
import React, { useState } from 'react';

import { Wrapper,Content } from "./models.style";
import Carousel from 'react-bootstrap/Carousel';

import Section from "../Section";
import Interior from "../Interior_Specs";
import Specs from "../Gen_Spec";
import Header from "../Header";
import Footer from "../Footer";
import useMediaQuery from '@mui/material/useMediaQuery';
import {isMobile} from 'react-device-detect';


const ModelS = () =>{


    document.title = "About Us";
    const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  

  if (!isMobile){
    return(

          <Wrapper>
            <Header/>
            
            {/* <Content>
                <Section 
                title="Model S" 
                desc="Plaid" 
                backgroundImg="model-s.jpg" 
                arrow="true" 
                range='396'
                speed='1.99'
                top='200'
                hp='1,020'
                />
            </Content> */}
<Content>
      <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
<picture>
  
        <img className="d-block w-100 h-100 hidden" class="w-100 h-100 d-block hidden"
        src="/images/bg-deca.png"
        />
        </picture>
        <Carousel.Caption>
          <h3>Career Development Team</h3>
          <p>Responsible for creating competitive event preparation!</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>

<picture>
   
        <img
          className="d-block w-100 h-100 hidden" class=" w-100 h-100 d-block hidden"
          src="/images/bg-deca.png"
        />
        </picture>
        <Carousel.Caption>
          <h3>Marketing Team</h3>
          <p>In charge of school store, marketing, and hosting DECA events!</p>
        </Carousel.Caption>
      </Carousel.Item>
   
    </Carousel>
</Content>
</Wrapper>


    );
}
return (
  <Wrapper>
  <Header/>
  
  {/* <Content>
      <Section 
      title="Model S" 
      desc="Plaid" 
      backgroundImg="model-s.jpg" 
      arrow="true" 
      range='396'
      speed='1.99'
      top='200'
      hp='1,020'
      />
  </Content> */}
<Content>
<Carousel activeIndex={index} onSelect={handleSelect}>
<Carousel.Item>
<picture>

  <img className="d-block w-100" class="w-100 d-block bg"
src="/images/verticalbg.png"
/>
</picture>
<Carousel.Caption>
<h3>Career Development Team</h3>
<p>Responsible for creating competitive event preparation!</p>
</Carousel.Caption>
</Carousel.Item>
<Carousel.Item>

<picture>

 <img className="d-block w-100" class=" d-block w-100 bg"
src="/images/verticalbg.png"
/>
</picture>
<Carousel.Caption>
<h3>Marketing Team</h3>
<p>In charge of school store, marketing, and hosting DECA events!</p>
</Carousel.Caption>
</Carousel.Item>

</Carousel>
</Content>
</Wrapper>
);




}

export default ModelS;
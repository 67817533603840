import React from "react";
import {isMobile} from 'react-device-detect';
import { Wrapper } from "./modelx.style";

import Section from "../Section";
import Default from "../Default"
import Interior from "../Interior_Specs";
import Specs from "../Gen_Spec";
import Header from "../Header";
import Footer from "../Footer";

const ModelX = () =>{
    document.title = "Calendar";

    if (!isMobile){
    return(
        <Wrapper >
            <Header/>
          
            <Default
                backgroundImg="bg-deca.png" 
            />
  
        </Wrapper>
    )
}
return(
    <Wrapper >
        <Header/>
      
        <Default
            backgroundImg="verticalbg.png" 
        />

    </Wrapper>
)
}

export default ModelX;
import React, { useState, useEffect } from 'react';
import { BsBagCheckFill } from 'react-icons/bs';
import { runFireworks } from '../lib';
import { useShoppingCart } from './Context.tsx';
import { Wrapper,Content } from "../Shop/shop.style";
const Success = () => {
    const {
        removeFromCart, cartItems,
    } = useShoppingCart()
    
    useEffect(() => {
      {cartItems.map(item => (
        removeFromCart(item.id)
      ))};
      runFireworks();
    }, []);
  
    return (
      <Wrapper >
        <div className="wrapper" style={{height: "100%", width: "100%", background: "#EBF0F5"}}>

        
        <Content>
        {/* <div className="success-wrapper">
        <div className="success">
          <p className="icon">
            <BsBagCheckFill />
          </p>
          <h2>Thank you for your order!</h2>
          <p className="email-msg" style={{color: "black"}}>Your Order # is: Feature to be Implemented</p>
          <p className="description">
            If you have any questions, please email
            <a className="email" href="mailto:shobhitagarwal122@gmail.com">
             shobhitagarwal122@gmail.com
            </a>
          </p>
          <div className='left'>
          <a href="/orders"><button>View Order</button></a>
            </div>
        </div>
      </div> */}
      <div className="wrapper">
      <div className="card">
      <div style={{borderRadius:"200px", height:"200px", width:"200px", background: "#F8FAF5", margin:"0 auto"}}>
        <i className="checkmark">✓</i>
      </div>
        <h1>Success</h1> 
        <p>We received your purchase request;<br/></p>
        <br></br>
        <div className="left">
        <a href="/orders"><button>View Order</button></a>
        </div>
        
      </div>
      </div>
    
        </Content>
        </div>
      </Wrapper>
   
    )
  }
  
  export default Success
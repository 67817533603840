import  Firebase  from "firebase/app";
// import { getAuth } from "firebase/auth";
import 'firebase/firestore'
import { useNavigate } from "react-router";
import firebase from 'firebase'
require('firebase/auth')

// import {signInWithEmailAndPassword} from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyC9P7gruR0y5jmlUAojY5UYqBI27ciNKMM",
  authDomain: "reedy-deca.firebaseapp.com",
  projectId: "reedy-deca",
  storageBucket: "reedy-deca.appspot.com",
  messagingSenderId: "609964626574",
  appId: "1:609964626574:web:ef5146809a5d6219586fdf"
};



// Initialize Firebase
const firebase2 = Firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    return 0;
  } catch (err) {
    console.error(err);
    console.log(err.message);
  }
};



const logout = () => {
  firebase.auth().signOut()
};

const db = firebase2.firestore();

// const auth = getAuth(firebase);

export {firebase, db, auth, logInWithEmailAndPassword, logout}

import React, { useEffect, useState } from 'react';

import { Wrapper,Content } from "./shop.style";
import Carousel from 'react-bootstrap/Carousel';

import Section from "../Section";
import Interior from "../Interior_Specs";
import Specs from "../Gen_Spec";
import Header from "../Header";
import Footer from "../Footer";
import useMediaQuery from '@mui/material/useMediaQuery';
import {isMobile} from 'react-device-detect';
import { useNavigate } from 'react-router';


const Shop = () =>{

  const navigate = useNavigate();
    document.title = "About Us";
    const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  

  if (!isMobile){
    return(

          <Wrapper>
            <Header/>
            
            {/* <Content>
                <Section 
                title="Model S" 
                desc="Plaid" 
                backgroundImg="model-s.jpg" 
                arrow="true" 
                range='396'
                speed='1.99'
                top='200'
                hp='1,020'
                />
            </Content> */}
<Content>
      <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
<picture>
  
        <img className="d-block w-100 h-100 hidden" class="w-100 h-100 d-block hidden"
        src="/images/drinks.webp"
        />
        </picture>
        <Carousel.Caption>
          <h3>Drinks</h3>
          <div className='left'>
          
          <a href="/drinks"><button>Buy Now</button></a>
            </div>
          {/* Add button here */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>

<picture>
   
        <img
          className="d-block w-100 h-100 hidden" class=" w-100 h-100 d-block hidden"
          src="/images/food.webp"
        />
        </picture>
        <Carousel.Caption>
          <h3>Food</h3>
          <div className='left'>
          <a href="/food"><button>Buy Now</button></a>
            </div>
          {/* <p>In charge of school store, marketing, and hosting DECA events!</p> */}
        </Carousel.Caption>
      </Carousel.Item>
   
    </Carousel>
</Content>
</Wrapper>


    );
}

window.location.href = window.location.protocol + '//' + window.location.host + '/drinks';




}

export default Shop;
import React, { Component, useEffect, useState } from 'react'
import { Wrapper,ContentTop,ContentMid,Content } from './video.style'
import { useNavigate } from 'react-router-dom'

// import video from '/images/test-vid.mp4'


function Video({ title,desc,backgroundImg,link,leftbtn,rightbtn,arrow,range,speed,hp,top }) {


    const navigate = useNavigate();

    const drinks = () => {
        navigate('/drinks')
    }

    const form = () => {
        navigate('')
    }

    return (
        <Wrapper>
                <video autoPlay playsInline muted loop>
                <source src="/Videos/test-vid.mp4" type="video/mp4"/></video>

                <ContentTop>
                    <h1 className="hp">{title}</h1>
                    <p>{desc}</p>
                </ContentTop>

                <div>
                    <ContentMid className={arrow ? '' : 'buttons'} >
                    
                        {leftbtn &&
                        <div className='left'>
                            <button className="hidden" onClick={form}>{leftbtn}</button>
                        </div>
                        }
                        {rightbtn && 
                            <div className='right'>
                                <button className="hidden" onClick={drinks}>{rightbtn}</button>
                            </div>
                        }
                    </ContentMid>
                
                    {arrow && 
                        <div className='arrow'>
                            <img src='/images/down-arrow.svg' alt='arrow'/>
                        </div>
                    }
                </div>
            </Wrapper>
        )
}

export default Video

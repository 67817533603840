import React, { useState } from "react";
import ScannerPlugin from "./ScannerPlugin";
import { db } from "../firebase";
import {QrReader} from 'react-qr-reader';
import { Grid } from "@mui/material";
import { Card, Col, Row, Stack } from "react-bootstrap";
import { formatCurrency } from "../Handler/formatCurrency.ts";
import { OrderItem } from "../Handler/OrderItem.tsx";
import List from './List'
import ReactDOM from 'react-dom'
import { Wrapper,Content } from "./scan.style";


// class Scanner extends React.Component {

//     constructor(props) {
//         super(props);

//         // This binding is necessary to make `this` work in the callback.
//         this.onNewScanResult = this.onNewScanResult.bind(this);
//     }

//     render() {
//         // const [show, setShow] = useState(false);

//         // const handleClose = () => setShow(false);
//         // const handleShow = () => setShow(true);
//         return (<div>

    
//             <h1>Html5Qrcode React example!</h1>
//             <ScannerPlugin 
//                 fps={10}
//                 qrbox={300}
//                 disableFlip={false}
//                 qrCodeSuccessCallback={this.onNewScanResult}/>
//         </div>
        
        
//         );
//         function modal(){
//             handleShow();
//         }
//     }

//     onNewScanResult(decodedText, decodedResult) {
//         // Handle the result here.
//         console.log(`${decodedText}`);
//         db.collection('orders').doc(decodedText).update({isCompleted: true});
//         // modal();
     
//     }
// }
// export default Scanner;
// const root = ReactDOM.createRoot(
//     document.getElementById('root')

//   );
let valid = false;
function round(num, places) {
    var multiplier = Math.pow(10, places);
    return Math.round(num * multiplier) / multiplier;
  }
  

export const Scanner = () => {

    const [scanResultWebcam, setScanResult] = useState("")

    const handleErrorWebCam = (error) => {
        console.log(error);
    }

    // const handleScanWebcam = (result) => {
    //     if (result){
    //         setScanResult(result);
    //         // console.log(result)
    //     }
    // }

    // const [items, setItems] = useState([])
    let completed = false;
    // let items = [];
    // const [items, setItems] = useState("")
    let items = [];
const fetchItems = async(id) => {
    const response = db.collection('orders').doc(id);
    const data = await response.get();
    //   console.log(data.data())
    //   const getItems = [];
    //   data.docs.forEach(item => {
    //     // console.log(item.data());
    //     console.log(item.data())
    //     getItems.push(item.data())
    //     // setItems([...items,item.data()]);
    //   })
    // setItems(data.data())
    items = data.data();
    // console.log(data.data());
    //   setItems(data.data());
    }



// useEffect(() => {
//     fetchItems(data);
//   }, [data]);
const render = (items) => {
    return(
    <Col className='col'>
                                    
    <Card style={{padding:"20px"}}>
            {/* // <Card.Img src={items[i][1].qr} style={{ objectFit: "" }}  /> */}
            hi
            <br></br>
            <Stack gap={3}>
            {[...Array(items['items'].length)].map((x,j) => {
                return(
                <OrderItem key={items['items'][j].id} id={items['items'][j]['id']} quantity={items['items'][j]['quantity']} />)
            })}
                <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                Total: {" "}{formatCurrency(round(items['total'], 2))}
                </div>
                <span className="text-muted" style={{ color: "black", fontSize: ".65rem" }}>
                 Date Order Placed At: {items['time']}
            </span>
            </Stack>

        </Card>
    </Col>  
    )
}
    return (
    
            <Wrapper>
           <Content>
<Row md={1} xs={1} lg={3} className="g-3 " >
<div style={{textAlign: "center"}} className="me-auto justify-content-center">
                    {/* <QrReader 
                    delay={300} onResult={(result, error) => {
                if (!!result) {
                    console.log(result?.text);
                    
                    (async function(){
                        // setScanResult(result?.text);
                        fetchItems(result?.text).then(() => {
                            var delayInMilliseconds = 3000; //1 second

                            setTimeout(function() {
                                console.log(items);
                                if (items['isCompleted'] === true){
                                    completed = true;
                                }

                                // if (completed){
                                //     return <List items={items}
                                // }else{

                                // }
                                // console.log(completed);
                                console.log(items['items'][0]['id'])
                                ReactDOM.render(<List items={{items}}></List>, document.getElementById('root'))
                                // {completed ?  <List items={items}/> : <List items={items}/>}
                            //    render(items);
                            //your code to be executed after 1 second
                            }, delayInMilliseconds);
                           
                        });
                        
                            
                    })();
                    
                    }
                    
                    if (!!error) {
                        // console.info(error);
                    }
                }

                
                } style={{width: "20%"}} className="align-self-start" constraints={{facingMode: 'environment'}} containerStyle={{height:30}} /> */}
                <ScannerPlugin fps={20} qrbox={250} disableFlip={false} qrCodeSuccessCallback={(result, error) => {
                if (!!result) {
                    const decoded = error['decodedText'];
                    
                    (async function(){
                        // setScanResult(result?.text);
                        
                        fetchItems(decoded).then(() => {
                            var delayInMilliseconds = 1000; //1 second

                            setTimeout(function() {
                                console.log(items);
                                try {
                                if (items['isCompleted'] === true){
                                    completed = true;
                                    valid = true;
                                }
                                }catch (e){
                                    
                                }
                                // if (completed){
                                //     return <List items={items}
                                // }else{

                                // }
                                // console.log(completed);
                                // console.log(items['items'][0]['id'])
                                // if (!valid){
                                ReactDOM.render(<> <List items={{items}}></List></>, document.getElementById('root'))
                                db.collection('orders').doc(decoded).update({isCompleted: true, status: "completed"})
                                // }else{
                                    // alert("Invalid QR Code")
                                // }
                                
                                // {completed ?  <List items={items}/> : <List items={items}/>}
                            //    render(items);
                            //your code to be executed after 1 second
                            }, delayInMilliseconds);
                           
                        });
                        
                            
                    })();
                    
                    }
                    
                    if (!!error) {
                        // console.info(error);
                    }
                }}/>
                </div>
                

                
                <div className="align-self-end">

                {/* <h3 style={{color: "black"}}>Order Information:</h3> */}
                </div>
                <div id="root"></div>
                
                
                </Row>
  
                </Content>
       </Wrapper>
    )
            }


export default Scanner;
import React from 'react'
import { Card } from 'react-bootstrap'
import {useShoppingCart} from '../Handler/Context.tsx'
import { formatCurrency } from './formatCurrency.ts'
import {Wrapper} from './video.style'
type StoreItemProps = {
    id: number
    name: string
    price: number
    imgUrl: string
    amount: number
}


export function StoreItem({id, name, price, imgUrl}: StoreItemProps){
    const {
        getItemQuantity, decreaseCartQuantity, increaseCartQuantity, removeFromCart,
    } = useShoppingCart()
    const quantity = getItemQuantity(id);
    return (
        <Card style={{border:"none"}}>
        <Card.Img src={imgUrl} style={{objectFit: "cover"}} height={window.innerWidth/4} width={window.innerWidth/4}/>

        <Card.Title id="test" className="d-flex justify-content-between"><h2 id="test" style={{marginTop: "15px"}}>{name}</h2> <span style={{display: "inline", verticalAlign: "middle", float: "right", marginTop: "5px",}}>{formatCurrency(price)}</span></Card.Title>
        {quantity === 0 ? (
            <div className="left"><button onClick={() => increaseCartQuantity(id)}>Add to Cart</button></div>
        ): <div className="d-flex align-self-start flex-column w-100" style={{gap: ".5rem"}}>
            <div className="d-flex align-self-start justify-content-center" style={{gap:".5rem"}}>
            <div className="circle"><button onClick={() => decreaseCartQuantity(id)}>-</button></div>
            <span className="fs-5">{quantity}</span>
            <div className="circle"><button onClick={() => increaseCartQuantity(id)}>+</button></div>
            </div>
            <div className="red"><button onClick={() => removeFromCart(id)}>Remove</button></div>
        </div> }

        </Card>

    )
}


import React,{ useState } from 'react'
import { Wrapper,Content,Content1 } from './header.style'
import { Link } from 'react-router-dom';

import SideNav from '../SideNav'
import CloseIcon from '@mui/icons-material/Close';

import {Button, Offcanvas} from 'react-bootstrap'
import { useShoppingCart } from '../Handler/Context.tsx';

const ShopHeader = () => {

    const [open,setOpen] = useState(false);
    const { openCart, cartQuantity } = useShoppingCart()

    return (
        <>
        <Wrapper>
            <div className='logo' >
            <Link to='/'>
                <img src='/images/shop.png' width="50" alt='logo'/>
            </Link>
            </div>
            <Content>
                <Link to='/about' class="text-dark">About Us</Link>
                <Link to='/#' class="text-dark">Compete</Link>
                <Link to='/calendar' class="text-dark">Calendar</Link>
                <Link to='/#' class="text-dark">Past Meetings</Link>
                <Link to='#' class="text-dark">Volunteer at School Store</Link>
                <Link to='#' class="text-dark">Hall of Fame</Link>
            </Content>
            <Content1>
              
                {/* <a href='/shop' className="none" class="text-dark none">School Store</a>
                <Link to='/login' className="none" class="text-dark none">Account</Link> */}
                {/* <Button style={{width: "3rem", height="3rem"}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg></Button> */}
                {cartQuantity > 0 && (
          <Button
            onClick={openCart}
            style={{ width: "2.5rem", height: "2.5rem", position: "relative", marginRight: "20px" }}
            variant="outline-primary"
            className="rounded-circle"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              fill="blue"
            >
              <path d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z" />
            </svg>

            <div
              className="rounded-circle bg-danger d-flex justify-content-center align-items-center"
              style={{
                color: "white",
                width: "1.5rem",
                height: "1.5rem",
                position: "absolute",
                bottom: 0,
                right: 0,
                transform: "translate(25%, 25%)",
              }}
            >
              {cartQuantity}
            </div>
          </Button>
        )}
                <Link to='/orders' class="text-dark none">My Orders</Link>
                <Link to='/shop' class="text-dark none">School Store</Link>
                <a href="#" className="particular" onClick={()=> setOpen(true)} class='text-dark particular'>Menu</a>
            </Content1>
            {open && 
            <div className='top'>
                <div className='close' onClick={()=> setOpen(false)}>
                    {/* <CloseIcon/> */}
                    <Offcanvas.Header closeButton></Offcanvas.Header>
                </div>
            </div> 
            }       
            <SideNav show={open}/>
        </Wrapper>
        </>
    )
}

export default ShopHeader

import React, { useEffect, useState } from 'react';

import { Wrapper,Content } from "./models.style";
import Carousel from 'react-bootstrap/Carousel';

import Section from "../Section";
import Interior from "../Interior_Specs";
import Specs from "../Gen_Spec";
import ShopHeader from "../ShopHeader";
import Footer from "../Footer";
import useMediaQuery from '@mui/material/useMediaQuery';
import {isMobile} from 'react-device-detect';
import { Button, Col, Row } from 'react-bootstrap';
import { StoreItem } from '../Handler/StoreItem.tsx';
import storeItems from "../data/items.json";
import Context from '../Handler/Context.tsx'
import {db} from '../firebase'




const Drinks = () =>{
  const [items, setItems] = useState([])
const fetchItems=async()=>{
  const response = db.collection('inventory');
  const data = await response.get();

  const getItems = [];
  data.docs.forEach(item => {
    // console.log(item.data());
    getItems.push(item.data())
    // setItems([...items,item.data()]);
  })
  setItems(getItems);
}

useEffect(() => {
  fetchItems();
}, [])

// console.log(items)
    const quantity  = 0;

    document.title = "About Us";
    const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  
  // if(!isMobile){

    return(

          <Wrapper>
            <ShopHeader/>
           
<Content>
<h1 class="text-dark">Drinks</h1>
            <div class="card">
            </div>

                <br></br>
            {/* <center>
            <div class="d-flex flex-row" >
              {items && items.map(item => (
                <div class="column" key={item.id}><StoreItem {...item}></StoreItem></div>
              ))}
            </div>
            </center>  */}

            <Row md={2} xs={1} lg={3} className="g-3 justify-content-md-center">
            {items && items.map(item => (
                <Col class="col" key={item.id}><StoreItem {...item}></StoreItem></Col>
              ))}
            </Row>
    
            

</Content>
</Wrapper>
);
//   }

//   return(

//           <Wrapper>
//             <ShopHeader/>
           
// <Content>
// <h1 class="text-dark">Drinks</h1>
//             <div class="card">
//             </div>
//             <br></br>


//             {/* <div class="container"> */}

//             <div class="container" >
//               <div class="row">
//               <div class="col">
//               {/* <img src="/images/1.png" height={window.innerWidth/2-60} width={window.innerWidth/2-60}/>
//               <li><h2 id="test">Dr. Pepper</h2></li>
//               <p id="test">$2.00</p>
//               <div className="left">
//               <button>Buy</button></div>
//               </div> 
//               <div class="col">
//               <img src="/images/2.png" height={window.innerWidth/2-60} width={window.innerWidth/2-60}/>
//               <li><h2 id="test">Coca-Cola</h2></li>
//               <p id="test">$1.50</p>
//               <div className="left">
//               <button>Buy</button></div>
//               </div>
//               <li></li>
//               <div class="col">
//               <img src="/images/3.png" height={window.innerWidth/3} width={window.innerWidth/3}/>
//               <li><h2 id="test">Snapple</h2></li>
//               <p id="test">$1.50</p>
//               <div className="left">
//               <button>Buy</button></div> */}

//           {items.map(item => (
//                 <div class="col" key={item.id}><StoreItem {...item}><br></br></StoreItem></div>
//               ))}
//               </div>
//             </div>
//             </div>
//             {/* </div> */}
            

// </Content>
// </Wrapper>
// );



}

export default Drinks;
import React from 'react'
import { Wrapper,Content } from './home.style'

import Section from './Section'
import Video from './Video'
import Header from './Header';
import Footer from './Footer';

function Home() {

    document.title = "Reedy DECA";
    return (
        <>
        <Header/>
        <Wrapper>
            <Content>
                <Video title="GET. THE. EDGE." desc="Reedy DECA is preparing emerging ICDC champions and creating a culture of entrepreneurship across the community. " link="Touchless Delivery" leftbtn="Join Now" rightbtn="Shop at The DEN" backgroundImg="model-3.jpg" />
            </Content>
        </Wrapper>
        </>
    )
}

export default Home
import {createContext, ReactNode, useContext, useState, useEffect } from "react";
import React from "react"
import { Cart } from "./Cart.tsx";
import { useLocalStorage } from "./useLocalStorage.ts";
import {db} from '../firebase'

type ProviderProps = {
    children: ReactNode
}

type CartItem = {
    id: number
    quantity: number
}
type Context = {
    openCart: () => void
    closeCart: () => void
    getItemQuantity: (id: number) => number
    increaseCartQuantity: (id: number) => void
    decreaseCartQuantity: (id: number) => void
    removeFromCart: (id: number) => void
    cartQuantity: number
    cartItems: CartItem[]
    
    
}


const Context = createContext({} as Context)

export function useShoppingCart() {
    return useContext(Context)
}


export function Provider ({children} : ProviderProps){

    const [isOpen, setIsOpen] = useState(false)


    const [cartItems, setCartItems] = useLocalStorage<CartItem[]>(
        "shopping-cart",
        []
      )

    // const [cartItems, setCartItems] = useState<CartItem[]>([])

    const openCart = () => setIsOpen(true)
    const closeCart = () => setIsOpen(false)

    const cartQuantity = cartItems.reduce((quantity, item) => item.quantity + quantity, 0)


    function getItemQuantity(id: number){
        return cartItems.find(item => item.id === id)?.quantity || 0
    }

    function increaseCartQuantity(id: number){
        setCartItems(currItems => {
            if (currItems.find(item => item.id === id) == null){
                return [...currItems, {id, quantity: 1}]
            }else{
                return currItems.map(item => {
                    if (item.id === id){
                        return {...item, quantity: item.quantity + 1}
                    }else{
                        return item
                    }
                })
            }
        })
    }

    function decreaseCartQuantity(id: number){
        setCartItems(currItems => {
            if (currItems.find(item => item.id === id)?.quantity === 1){
                return currItems.filter(item => item.id != id)
            }else{
                return currItems.map(item => {
                    if (item.id === id){
                        return {...item, quantity: item.quantity - 1}
                    }else{
                        return item
                    }
                })
            }
        })
    }

    function removeFromCart(id: number){
        setCartItems(currItems => {
            return currItems.filter(item => item.id != id)
        })
    }
    return (
    <Context.Provider value={{getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart, openCart, closeCart, cartItems, cartQuantity, }}>
        {children}
        <Cart isOpen={isOpen} />
    </Context.Provider>
    )
}
import React, { useEffect } from "react";
import {logout} from "../../firebase"
import firebase from "firebase/app"

import names from "./namesupdated.txt"
import grades from "./grades.txt"
import ids from "./ids.txt"

// const {readFileSync, promises: fsPromises} = require('fs')

const CreateAccounts = () => {

    let users = []; //placeholder

    function generatePassword() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    useEffect(() => {

        // const names = readFileSync('namesupdated.txt', 'utf-8')
        // const grades = readFileSync('grades.txt', 'utf-8')
        // const ids = readFileSync('ids.txt', 'utf-8')
        
        // const list_names = names.split('\n')
        // const list_grades = grades.split('\n')
        // const list_ids = ids.split('\n')

        let list_names = [];
        let list_grades = [];
        let list_ids = [];
        const makeUsers = (names, grades, ids) => {
            console.log(names[0].length)
            for (let i = 0; i < names[0].length; i++){

                (function(ind) {
                    setTimeout(function(){
                        const name = names[0][i];
                        const email = ids[0][i] + "@reedydeca.org"; 
                        const grade = grades[0][i];
                        console.log(name)
                        console.log(grade)
                        if (parseInt(grade.split("t")[0]) < 12){
                            const password = generatePassword();
                            try {
                                firebase.auth().createUserWithEmailAndPassword(email, "GetTheEdge").then(function(result) {
                                    result.user.updateProfile({
                                        displayName: name
                                    });       
                                }).then(logout)
                            }catch(e){

                            }
                            
                        }

                    }, 1000*ind);
                })(i);
                
                
            }
        }

        const fetchText = (() => {
            fetch(names).then(r => r.text()).then(text => {
                list_names.push(text.split("\n"))
            })
    
            fetch(grades).then(r => r.text()).then(text => {
                list_grades.push(text.split("\n"))
            })
    
            fetch(ids).then(r => r.text()).then(text => {
                list_ids.push(text.split("\n"))
            }).then(() => {
                makeUsers(list_names, list_grades, list_ids)
            })
        
            // console.log(names.text())
            console.log(list_names, list_grades, list_ids)
            
            try {
                
            }catch(e){
                console.log(e)
            }
        })
            
      

        fetchText()
        

    })

    return (
        <>
        </>
    )

}

export default CreateAccounts;
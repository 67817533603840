import React, { useState } from "react";
import LanguageIcon from '@mui/icons-material/Language';
import { logInWithEmailAndPassword } from "../firebase";

import { Wrapper,Content } from "./login.style";
import Footer from "../Footer";
import { Link,useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { useDispatch } from "react-redux";
import { login } from "../../features/userSlice";
import firebase from "firebase"

const Login = () =>{

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [login, updateLogin] = useState(true)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const signIn = (e) =>{
        e.preventDefault() //Doesn't reload page after click on button

        logInWithEmailAndPassword(email,password).then((returnVal) => {
            if (returnVal === 0){
                // const user = firebase.auth().currentUser;
                // user.updateProfile({displayName: "Shobhit Agarwal"}) --> this line will be used to add names
                navigate('/account')
            }else{
                updateLogin(false)
            }
        })

        
    }

    document.title = 'Sign In'
    return(
        <>
        <Wrapper>
            <div className="header" >
                <div>
                    <Link to='/'>
                        <img src="/images/shop.png" width="50" alt="logo"/>
                    </Link>
                </div>
                <div className="lang" style={{color: "black"}}>
                    <LanguageIcon style={{fill: 'black'}}></LanguageIcon> <span style={{color: "black"}}>en-US</span>
                </div>
            </div>

            <Content>
                <h1>Sign In</h1>
                <form>
                    <label htmlFor="email" >Email Address</label>
                    <input type='' id="email" placeHolder="Enter your DECA ID" value={email} onChange={(e)=> setEmail(e.target.value) }/>
                    <label htmlFor="password">Password</label>
                    <input type='password' id="password" placeHolder="Password" value={password} onChange={(e)=> setPassword(e.target.value) }/>

                    <button type="submit" onClick={signIn}>Sign In</button> {/* add onclick here */}

                    {login ? <></> : <div class="alert alert-danger" role="alert">
                        Invalid Username or Password
                    </div>}
                    
                </form>
                {/* <div className="line" >
                    <hr style={{backgroundColor: "black"}}/><span>OR</span><hr/>
                </div>

                <div>
                <Link to='/signup'>
                    <button>Create Account</button>
                </Link>
                </div> */}
            </Content>
            
        </Wrapper>
        {/* <Footer/> */}
        </>
    )
}

export default Login;
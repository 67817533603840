import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import axios from "axios"
import React, { useEffect, useState } from "react"
// import {PaymentForm} from "react-square-web-payments-sdk"
import PaymentForm from "./PaymentForm"


const PUBLIC_KEY = "pk_test_51LNpQ7BQtIlobxpX3EqvGmAtjxTN1u8h3qgzwLhZscWI3hbMENDp9dtBPhItcpyCRnL3NgpdS8HvKQ9DQfLg1rC900EneFfA4T"

const stripeTestPromise = loadStripe(PUBLIC_KEY)

type props = {
	amount: number
}
export default function StripeContainer({amount} : props) {
	  const [clientSecret, setClientSecret] = useState("");
	useEffect(() => {
		fetch("https://serene-anchorage-34493.herokuapp.com/payment", {
		  method: "POST",
		  headers: { "Content-Type": "application/json" },
		  body: JSON.stringify({ amount: Math.round(amount*100), id: '' }),
		})
		  .then((res) => res.json())
		  .then((data) => setClientSecret(data.clientSecret));
	  }, []);
	 
	  const appearance = {
		theme: 'stripe',
	  };
	  const options = {
		clientSecret,
		appearance,
		loader: "always",
	  };

	return (
		// <Elements stripe={stripeTestPromise}>
		// 	<PaymentForm />
		// </Elements>
			// <Elements stripe={stripeTestPromise} options={clientSecret}>
			// <PaymentForm />
			// </Elements>
			<>
			{clientSecret && (
				<Elements options={options} stripe={stripeTestPromise}>
				  <PaymentForm />
				</Elements>
			  )}
			  </>
	
	)
}
import React,{ useState } from 'react'
import { Wrapper,Content,Content1 } from './header.style'
import { Link } from 'react-router-dom';

import SideNav from '../SideNav'
import CloseIcon from '@mui/icons-material/Close';
import { Offcanvas } from 'react-bootstrap';

const Header = () => {

    const [open,setOpen] = useState(false);

    return (
        <>
        <Wrapper>
            <div className='logo' >
            <Link to='/'>
                <img src='/images/deca_logo.png' alt='logo'/>
            </Link>
            </div>
            <Content>
                <Link to='/about' class="text-light">About Us</Link>
                <Link to='#' class="text-light">Compete</Link>
                <Link to='/calendar' class="text-light">Calendar</Link>
                <Link to='#' class="text-light">Past Meetings</Link>
                <Link to='#' class="text-light">Volunteer at School Store</Link>
                <Link to='#' class="text-light">Hall of Fame</Link>
            </Content>
            <Content1>
                {/* <a href='/shop' className="none" class="text-light none">School Store</a> */}
                <a href='#' className="" onClick={()=> setOpen(true)} class='text-light'>Menu</a>
            </Content1>
            {open && 
            <div className='top'>
                <div className='close' onClick={()=> setOpen(false)}>
                    {/* <CloseIcon/> */}
                    <Offcanvas.Header closeButton></Offcanvas.Header>
                </div>
            </div> 
            }       
            <SideNav show={open}/>
        </Wrapper>
        </>
    )
}

export default Header
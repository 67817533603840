import React,{ useState } from 'react'
import { Wrapper,Content,Content1 } from './header.style'
import { Link } from 'react-router-dom';

import SideNav from '../SideNav'
import CloseIcon from '@mui/icons-material/Close';
import { Offcanvas } from 'react-bootstrap';

const DashboardHeader = () => {

    const [open,setOpen] = useState(false);

    return (
        <>
        <Wrapper>
            <div className='logo' >
            <Link to='/'>
                <img src='/images/shop.png' width="50" alt='logo'/>
            </Link>
            </div>
            <Content>
                <Link to='/calendar' class="text-dark">Calendar</Link>
                <Link to='#' class="text-dark">Past Meetings</Link>
                <Link to='#' class="text-dark">Volunteer at School Store</Link>
            </Content>
            <Content1>
                <Link to='/drinks' class="text-dark none" className="text-dark none">School Store</Link>
                <a href='#' className="particular" onClick={()=> setOpen(true)} class='text-dark particular'>Menu</a>
            </Content1>
            {open && 
            <div className='top'>
                <div className='close' onClick={()=> setOpen(false)}>
                    {/* <CloseIcon style={{fill: "black"}}/> */}
                    <Offcanvas.Header closeButton></Offcanvas.Header>
                </div>
            </div> 
            }       
            <SideNav show={open}/>
        </Wrapper>
        </>
    )
}

export default DashboardHeader
import React, { useEffect, useState } from "react";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { Wrapper,ContentTop,ContentMid,Content } from './default.style'
import { Calendar } from '@fullcalendar/core';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

let INITIAL_EVENTS = [
    {
        googleCalendarId:'n96l55k7ravleru1sahdq708fs@group.calendar.google.com',
        className:'event'
    }
]
function Section({ title,desc,backgroundImg,link,leftbtn,rightbtn,arrow,range,speed,hp,top }) {
    // const { height, width } = useWindowDimensions();
    return (
        <Wrapper bg={backgroundImg} >
            <ContentMid><FullCalendar
        plugins={[dayGridPlugin, googleCalendarPlugin, dayGridPlugin, bootstrap5Plugin]}
        initialView="dayGridMonth"
        googleCalendarApiKey="AIzaSyB-MlCyIanb1fdElrMjpF___O3ipShn1N0"
        eventSources={INITIAL_EVENTS}
        // initialView="dayGridMonth"
        height={window.innerHeight-100}
        
        /></ContentMid>
           
        </Wrapper>
        
    )
}

export default Section

import React from "react";
import { Wrapper, } from "./dashboard.style";
import {Content} from "../Account/account.style"
import Table from 'react-bootstrap/Table';
import { db } from "../firebase";
// import {collection, getDocs} from "firebase/firestore"
import { useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { Comparator, customFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { formatCurrency } from "../Handler/formatCurrency.ts"
import { Button, Card, Col, Row, Stack } from 'react-bootstrap';
import { OrderItem } from '../Handler/OrderItem.tsx';


// import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import DashboardHeader from "../DashboardHeader";
import Navigation from "./Navigation";



function custom_sort(a, b) {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
}

const Dashboard = () => {


    
    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    function round(num, places) {
        var multiplier = Math.pow(10, places);
        return Math.round(num * multiplier) / multiplier;
    }

    const [items, updateItems] = useState();
    const [keys, updateKeys] = useState();
    const [display, updateDisplay] = useState(false);
    const [orders, updateOrders] = useState([]);
    let temp = [];
    useEffect(() => {
      const fetchItems = async()=>{
      const response = db.collection('orders');
      const data = await response.get();

      const items = data.docs.map(doc => doc.data());
      const keys = data.docs.map(doc => doc.id)
      console.log("rerendering?")
        // let items = [];
        // let keys = [];

    //   console.log(keys)
    //   console.log(items)
      //set values of the "items" and "keys" variables
      updateItems(items)
      updateKeys(keys)
    
      
      for (let i = 0; i < items.length; i++){
        if (items[i]['status'] === "ordered"){
            temp.push({id: keys[i], status: items[i]['status'], total: formatCurrency(items[i]['total']), date: items[i]['time']});
        }
        // temp.push({id: keys[i], status: items[i]['status'], total: formatCurrency(items[i]['total']), date: items[i]['time']})
        
      }
      temp = temp.sort(custom_sort).reverse()

      updateOrders(temp)

      db.collection('orders').onSnapshot((snapshot) => {
        let changes = snapshot.docChanges();
        changes.forEach((change) => {
          if (change.type === 'modified') {
            
            if (!keys.includes(change.doc.id)){
                items.push(change.doc.data())
                keys.push(change.doc.id)
                // console.log(change.doc.data())
                console.log(keys)
                updateItems(items)
                updateKeys(keys)
                
                temp.push({id: keys[items.length-1], status: items[items.length-1]['status'], total: formatCurrency(items[items.length-1]['total']), date: items[items.length-1]['time']})
                temp = temp.sort(custom_sort).reverse()
                // console.log(temp)
                updateOrders(temp)
                

                updateDisplay(!display)
                
            }else{
                // console.log(keys, items)
                items[getKeyByValue(keys, change.doc.id)] = change.doc.data()
                updateItems(items)
                temp = [];
                for (let i = 0; i < items.length; i++){
                    if (items[i]['status'] === "ordered"){
                    temp.push({id: keys[i], status: items[i]['status'], total: formatCurrency(items[i]['total']), date: items[i]['time']});
                    }
                }
                temp = temp.sort(custom_sort).reverse()
                updateOrders(temp);
                // console.log(temp, orders)
            }
            
            // console.log(items)
            
            // console.log(items.slice(0, Math.ceil(items.length/2)))
          }
        
        });
        
      });

    //   console.log(items)
      }
      fetchItems()
      }, []);

      function getOrders(){
        console.log("Table Change Detected!")
        db.collection('orders').get().then(response => {
            // const orders = response.docs.map(doc => ({
            //     id: doc.id, status: doc.data()['status'], total: formatCurrency(doc.data()['total']), date: doc.data()['time']
            // }))
            // updateOrders(orders)
            const items = response.docs.map(doc => doc.data());
            const keys = response.docs.map(doc => doc.id)

            for (let i = 0; i < items.length; i++){
                if (items[i]['status'] === "ordered"){
                    temp.push({id: keys[i], status: items[i]['status'], total: formatCurrency(items[i]['total']), date: items[i]['time']});
                }
                
            }

            temp = temp.sort(custom_sort).reverse()

            updateOrders(temp)
        

            db.collection('orders').onSnapshot((snapshot) => {
                let changes = snapshot.docChanges();
                changes.forEach((change) => {
                  if (change.type === 'modified') {
                    
                    if (!keys.includes(change.doc.id)){
                        items.push(change.doc.data())
                        keys.push(change.doc.id)
                        // console.log(change.doc.data())
                        console.log(keys)
                        updateItems(items)
                        updateKeys(keys)
        
                        temp.push({id: keys[items.length-1], status: items[items.length-1]['status'], total: formatCurrency(items[items.length-1]['total']), date: items[items.length-1]['time']})
                        temp = temp.sort(custom_sort).reverse()

                        updateOrders(temp)
                        
        
                        updateDisplay(!display)
                        
                    }else{
                        temp = [];
                        for (let i = 0; i < items.length; i++){
                            if (items[i]['status'] === "ordered"){
                                temp.push({id: keys[i], status: items[i]['status'], total: formatCurrency(items[i]['total']), date: items[i]['time']});
                            }
                            // updateOrders(temp);
                        }
                        temp = temp.sort(custom_sort).reverse()
                        updateOrders(temp);
                    }
                    
                    // console.log(items)
                    
                    // console.log(items.slice(0, Math.ceil(items.length/2)))
                  }
                
                });
        })
      })
    }

      

    function statusFormatter(cell, row) {
    if (row.status === "completed") {
        return (
        <span>
            <strong className="badge bg-success">Fulfilled</strong>
        </span>
        );
    }else if (row.status === "ramen"){
        return (
            <span>
                <strong className="badge bg-info">Ramen</strong>
            </span>
        );
    }else{
        return (
            <span>
                <strong className="badge bg-danger">Ordered</strong>
            </span>
        );
    }
    }


    try {


        const filterByTime = (data) => {

            
            return data.filter(orders => Date.parse(orders.date) > new Date())
            
            
        }

        const columns = [
            {
                dataField: 'id',
                text: 'Order ID'
            },
            {
                dataField: 'status',
                text: 'Status',
                formatter: statusFormatter,
            },
            {
                dataField: 'total',
                text: 'Total',
            },
            {
                dataField: 'date',
                text: 'Order Date'
            }
        ]

     
        // let orders = [];
        function rerender(){
                // console.log(items.length)
                console.log("hi")
                // console.log(items)
                // orders.push({id: keys[items.length-1], status: items[items.length-1]['status'], total: formatCurrency(items[items.length-1]['total']), date: items[items.length-1]['time']})
                // console.log(orders)
                // setTimeout(() => {
                //     console.log("delay?")
                //     orders.push({id: keys[items.length-1], status: items[items.length-1]['status'], total: formatCurrency(items[items.length-1]['total']), date: items[items.length-1]['time']})
                //   }, 5000)
                //   console.log("before orders is pushed")
                
                // updateOrders(orders)
                // console.log(orders)
                
        
         
        }
        // try {
        // // console.log(items.length)
        // // console.log(orders)

     
    
    
        // // console.log(orders)

        // // updateOrders(orders)
        // // console.log(orders)
        

        // }catch(e){

        // }
        
        const expandRow = {
            renderer: row => (
                <div>
                    <Col className='col'>
                                        
                    <Card style={{padding:"20px"}}>
                            <br></br>
                            <Stack gap={3}>
                            {[...Array(items[getKeyByValue(keys, row.id)]['items'].length)].map((x,j) => {
                                return(
                                <OrderItem key={items[getKeyByValue(keys, row.id)]['items'][j]['id']} {...items[getKeyByValue(keys, row.id)]['items'][j]} />
                                )
                            })}
                            <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                                Processing Fees:{" "}{formatCurrency(round(items[getKeyByValue(keys, row.id)]['processing'], 2))}
                                </div>
                                <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                                Total: {" "}{formatCurrency(round(items[getKeyByValue(keys, row.id)]['total'], 2))}
                                </div>
                                <span className="text-muted" style={{ color: "black", fontSize: ".65rem" }}>
                                    Date Order Placed At: {items[getKeyByValue(keys, row.id)]['time']}
                            </span>
                            </Stack>

                        </Card>

                    </Col> 

    
                </div>


            ),
            showExpandColumn: true
        };
        return (

            <>
            
            <DashboardHeader/>
        <Wrapper>

            <Navigation></Navigation>
            <Content>
              
             {/* <Wrapper>
                 <DashboardHeader/>
                 <br></br> */}
             {/* <Content> */}
        
                 <div style={{width: "75rem", paddingLeft: "0rem", marginTop: "50px;"}}>

                <BootstrapTable  keyField="id" onTableChange={getOrders} columns={columns} data={orders} expandRow={expandRow} bordered={ false }/>

                </div>
            

             {/* </Content> */}

             {/* </Wrapper> */}
                
            </Content>

            
        </Wrapper>
            
            
            </>







        
        )
       

    }catch(e){

    }


}

export default Dashboard;
import { Button, Stack } from "react-bootstrap"
import { useShoppingCart } from "./Context.tsx"
import storeItems from "../data/items.json"
import { formatCurrency } from "./formatCurrency.ts"
import React, { useEffect, useState } from "react"
import { db } from "../firebase"


type CartItemProps = {
  id: number
  quantity: number
}



export function CartItem({ id, quantity }: CartItemProps) {
  const { removeFromCart } = useShoppingCart();


  const [items, setItems] = useState([])
const fetchItems=async()=>{
  const response = db.collection('inventory');
  const data = await response.get();

  const getItems = [];
  data.docs.forEach(item => {
    // console.log(item.data());
    getItems.push(JSON.parse(JSON.stringify(item.data())))
    // console.log(JSON.stringify(item.data()))
    // setItems([...items,item.data()]);
  })
  // console.log(getItems);
  // console.log(storeItems);
  setItems(getItems);
}

useEffect(() => {
  fetchItems();
}, [])


  const item = items.find(i => i.id === id)
  if (item == null) return null

  return (
    <>
    <Stack direction="horizontal" gap={2} className="d-flex align-items-center">
      <img
        src={item.imgUrl}
        style={{ width: "125px", height: "75px", objectFit: "cover" }}
      />
      <div className="me-auto">
        <div style={{color: "black",}}>
          <div style={{color: "black",}}>{item.name}{" "}</div>
          {quantity > 1 && (
            <span className="text-muted" style={{ color: "black", fontSize: ".65rem" }}>
              x{quantity}
            </span>
          )}
        </div>
        <div className="text-muted" style={{ fontSize: ".75rem", color: "black"}}>
          {formatCurrency(item.price)}
        </div>
      </div>
      <div style={{color: "black",}}> {formatCurrency(item.price * quantity)}</div>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={() => removeFromCart(item.id)}
      >
        &times;
      </Button>
    </Stack>
    <br></br>
    </>
   
  )
}
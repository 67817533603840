import React,{ useEffect } from 'react';
import { BrowserRouter as Router ,Routes, Route, Navigate } from 'react-router-dom';

// Using AOS for animation

// Components
import GlobalStyle from './globalstyles';
import Home from './components/home';
import ModelS from './components/About';
import Model3 from './components/Model-3';
import ModelX from './components/Calendar';
import ModelY from './components/Model_Y';
import Login from './components/Login';
import Signup from './components/Signup';
import Account from './components/Account';
import Shop from './components/Shop'
import Drinks from './components/Drinks'
import Success from './components/Handler/Success.tsx'
import Orders from './components/Orders/index';
import Dashboard from './components/Dashboard'
import Compete from './components/Compete'

import firebase from 'firebase'
import { useSelector,useDispatch } from 'react-redux';
import { selectUser,login,logout } from './features/userSlice';
// import { auth } from './components/firebase';
import { Provider } from './components/Handler/Context.tsx';

import ScannerPlugin from './components/Scan/ScannerPlugin';
import Scanner from './components/Scan';
import AccountHome from './components/Dashboard/AccountHome';
import CreateAccounts from './components/Dashboard/Helpers/createaccounts'
import PBA from './components/PBA';
import PFN from './components/PFN';
import PMK from './components/PMK';
import PHT from './components/PHT';
import BLTDM from './components/BLTDM';

require('firebase/auth')
function App() {

  const user = useSelector(selectUser)

  const dispatch = useDispatch()

  function requireAuth(nextState, replace, next) {
    const user = firebase.auth().currentUser;
    console.log("hi")
    if (!user){
      replace({
        pathname: "/login",
        state: {nextPathname: nextState.location.pathname}
      });
    }
    next();
   
  }

  // useEffect(() => {
  //   auth.onAuthStateChanged((userAuth) => {
  //     if (userAuth) {
  //       // User is signed in
  //       dispatch(
  //         login({
  //           email: userAuth.email,
  //           uid: userAuth.uid,
  //           displayName: userAuth.displayName,
  //         })
  //       )
  //     } else {
  //       // User is signed out
  //       dispatch(logout())
  //     }
  //   })
  // }, [dispatch])

  return (

    <Provider>
    {/* <Router> */}
      <div className="App">
        <Routes>
          
          <Route path='/' element={<Home/>}/>
          <Route path="/about" element={<ModelS/>} />
          <Route path="/calendar" element={<ModelX/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/shop" element={<Shop/>} />
          <Route path="/drinks" element={<Drinks/>} />
          <Route path="/success" element={<Success/>} />
          <Route path="/orders" element={<Orders/>} />
          <Route path="/scan" element={<Scanner/>} />
          <Route path="/dashboard" element={<Dashboard/>} onEnter={requireAuth}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/account" element={<AccountHome/>} onEnter={requireAuth}/>
          <Route path="/create" element={<CreateAccounts/>} onEnter={requireAuth}/>
          <Route path="/compete" element={<Compete/>} onEnter={requireAuth}/>
          <Route path="/competitive-events/principles-of-business-management-and-administration" element={<PBA/>} onEnter={requireAuth}/>
          <Route path="/competitive-events/principles-of-finance" element={<PFN/>} onEnter={requireAuth}/>
          <Route path="/competitive-events/principles-of-marketing" element={<PMK/>} onEnter={requireAuth}/>
          <Route path="/competitive-events/principles-of-hospitality" element={<PHT/>} onEnter={requireAuth}/>
          <Route path="/competitive-events/business-law-and-ethics-team-decision-making" element={<BLTDM/>} onEnter={requireAuth}/>
          {/* <Route path="/competitive-events/buying-and-merchandising-team-decision-making" element={<BTDM/>} onEnter={requireAuth}/>
          <Route path="/competitive-events/entrepreneurship-team-decision-making" element={<ETDM/>} onEnter={requireAuth}/>
          <Route path="/competitive-events/financial-services-team-decision-making" element={<FTDM/>} onEnter={requireAuth}/>
          <Route path="/competitive-events/hospitality-services-team-decision-making" element={<HTDM/>} onEnter={requireAuth}/>
          <Route path="/competitive-events/marketing-management-team-decision-making" element={<MTDM/>} onEnter={requireAuth}/>
          <Route path="/competitive-events/sports-and-entertainment-marketing-team-decision-making" element={<STDM/>} onEnter={requireAuth}/>
          <Route path="/competitive-events/travel-and-tourism-team-decision-making" element={<TTDM/>} onEnter={requireAuth}/>           */}
          <Route 
            path="/teslaaccount" 
            element={
              <main>
                {user ? <Account/> : <Navigate to='/login'/>}
              </main>
            }
          />
          <Route 
            path="/login" 
            element={
              <main>
                {user ? <Navigate to='/teslaaccount'/> : <Login/>}
              </main>
            } 
          />

        </Routes>

        <GlobalStyle/>
      </div>
    {/* </Router> */}
    </Provider>
  );
}

export default App;
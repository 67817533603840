import React, { useEffect, useState } from "react";


import { Wrapper,Content } from "../Account/account.style";
import { Button, Card, Col, Row, Stack } from 'react-bootstrap';
import DashboardHeader from "../DashboardHeader";
import Navigation from "./Navigation";
import { useNavigate } from "react-router";
import firebase from 'firebase'
require('firebase/auth')

const AccountHome = () => {
    document.title = "Profile";
    let navigate = useNavigate();
    const [user, updateUser] = useState()
useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            updateUser(user)
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          var uid = user.uid;
          // ...
        } else {
            navigate('/login')
        }
      });
}, [])

return (
        <> 
        {user ? <><DashboardHeader/>
        <Wrapper>
    
        <Navigation></Navigation>
        <Content>
            <h1>Hi, {user.displayName} </h1>
            <div className="container" >
                <Row md={2} xs={1} lg={3} className="g-5">
                    <Col>
                        <Card style={{padding:"20px", width:"18rem", height: "18rem", borderColor: "black"}}>
                        <center>
                                        <div className="ms-auto fw-bold fs-2" style={{color: "black", width:"100%"}}>
                                            DECA Points: 
                                            </div></center>
    
                        <Card.Body>
    
                            <center>
                                <h1 style={{color: "black"}}>0 <br></br>Points</h1>
                            </center>
    
                            
                        </Card.Body>
    
                                
                                
    
    
                        </Card>
                    </Col>     
                
                    <Col>
                        <Card style={{padding:"20px", width:"18rem", height: "18rem", borderColor: "black"}}>
                        <center>
                                        <div className="ms-auto fw-bold fs-2" style={{color: "black", width:"100%"}}>
                                            Free Snack At:
                                            </div></center>
    
                        <Card.Body>
    
                            <center>
                                <h1 style={{color: "black"}}>1000 <br></br>Points</h1>
                            </center>
    
                            
                        </Card.Body>
    
                                
                                
    
    
                        </Card>
                    </Col>   
    
                    <Col>
                        <Card style={{padding:"20px", width:"18rem", height: "18rem", borderColor: "black"}}>
                        <center>
                                        <div className="ms-auto fw-bold fs-2" style={{color: "black", width:"100%"}}>
                                            Free Drink At:
                                            </div></center>
    
                        <Card.Body>
    
                            <center>
                                <h1 style={{color: "black"}}>1500 <br></br>Points</h1>
                            </center>
    
                            
                        </Card.Body>
    
                                
                                
    
    
                        </Card>
                    </Col>           
                </Row>
                {/* <div className="box box1" >
                    <div className="desc" >
                        <h3>Order Tesla Solar</h3>
                        <p>Produce energy to power your Tesla life</p>
                        <a href="#" >View Solar</a>
                    </div>
                </div>
                <div className="box box2">
                    <div className="desc" >
                        <h3>Reserve a Car</h3>
                        <p>Browse our models</p>
                        <Link to='/'>Shop Now</Link>
                    </div>
                </div>
                <div className="box_fade" >
                    <div className="box3" ></div>
                    <div className="desc fade" >
                        <h3>Purchased a car from a third party?</h3>
                        <a href="#">Add</a>
                    </div>
                </div> */}
            </div>
    
            
        </Content>
    
      </Wrapper>
        </> : <></>}
        </>
    )
}
export default AccountHome;
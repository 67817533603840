import styled from 'styled-components';

export const Wrapper = styled.div`
    // overflow-x: hidden;
    // width: 100vw;
    height: 100vh;
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    @media screen and (max-width: 400px){
        padding-top: 10vh;
    }
    h2{
        margin-top: 5px;
    }
    padding-top: 12vh;
    display: flex;
    overflow-x: inherit;
    
      
th, td, tr{
    color:black;
}
thead {
    background: #dddcdc;
  }
  
`;

export const Content = styled.div`

    margin-top: 40px;
    padding-right: 200px;
    margin-left: 150px;
    h2{

    color:black;
    font-family: Gotham;
    font-size: 2rem;
    }

    h2 span{
        font-family: Gotham;
        color:black;
        font-size: 1rem;
    }
    p{
        font-family: Gotham;
        color:black;

    }
span{
    color:black;
}
    button{
        
        color:white;
    }

    .circle button{
        // padding: 12px 20px;
        background-color: rgba(23,26,32,1);
        width: 30px;
        height:30px;
        border-radius: 20px;
        outline: none;
        border: none;
        font-size: 0.8rem;
        font-weight: 400;
        letter-spacing: 0.6px;
        opacity: 0.9;
        :hover{
            cursor: pointer;
        }
    }
    .red button{
        background-color: #db1b0d;
        padding: 12px 20px;
        width: 100%;
        border-radius: 20px;
        outline: none;
        border: none;
        font-size: 0.8rem;
        font-weight: 400;
        letter-spacing: 0.6px;
        opacity: 0.9;
        :hover{
            cursor: pointer;
        }
    }
    .new button{
        background-color: rgba(23,26,32,1);
        // padding: 12px 20px;
        width: 30%;
        border-radius: 20px;
        outline: none;
        border: none;
        font-size: 0.8rem;
        font-weight: 400;
        letter-spacing: 0.6px;
        opacity: 0.9;
        :hover{
            cursor: pointer;
        }
    }
    .left button{
        
        // margin-right: 25px;
        background-color: rgba(23,26,32,1);
        padding: 12px 20px;
        width: 100%;
        border-radius: 20px;
        outline: none;
        border: none;
        font-size: 0.8rem;
        font-weight: 400;
        letter-spacing: 0.6px;
        opacity: 0.9;
        :hover{
            cursor: pointer;
        }
    }

    #test{
        text-align:left;
    }
    .d-flex{
        justify-content: space-evenly;
        // margin: 0 rem;
    }


    .column {
        // justify-content: space-evenly;
        
        // margin: 0 rem;
      }

    //   .row {  margin: 0; }

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
//   @media screen and (max-width: 600px) {
//     .column {
//       width: 100%;
//       display: block;
//       margin-bottom: 20px;
//     }
}
`;
import React from 'react'
import { Wrapper,Content} from './sidenav.style'

import { Link } from 'react-router-dom'
import { Offcanvas } from 'react-bootstrap'

function SideNav({ show }) {

    
    return (
        <Wrapper show={show}>
            <Content>
        
     
            <Link to='/shop'><li className=''>School Store</li></Link>
            <Link to='/orders'><li className=''>My Orders</li></Link>
                {/* <li className='half'>School Store</li> */}
                {/* <li className='half'>Account</li> */}
                 <Link to='/about'><li className='half'>About Us</li></Link>
                <Link to='/'><li className='half'>Compete</li></Link>
                <Link to='/calendar'><li className='half'>Calendar</li></Link>
                <Link to='/'><li className='half'>Past Meetings</li></Link>
                <li className='half'>Volunter at School Store</li>
                <li className='half'>Hall of Fame</li> 
                <Link to='/teslaaccount'><li className=''>Log In</li></Link>
            </Content>
        </Wrapper>
    )
}

export default SideNav
import { CardElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import axios from "axios"
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router";
// import { browserHistory } from 'react-router-dom';
import  "./form.css";
import StripeContainer from "./StripeContainer.tsx";
import { useHistory } from 'react-router-dom';
import { useShoppingCart } from "../Handler/Context.tsx";
import { db } from "../firebase";

// import Checkout from "../Handler/Checkout.tsx"

const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			// iconColor: "#c4f0ff",
			color: "#000",
			// fontWeight: 500,
			// fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "15px",
			// fontSmoothing: "antialiased",
			// ":-webkit-autofill": { color: "#fce883" },
			// "::placeholder": { color: "#87bbfd" }
		},
		invalid: {
			// iconColor: "#ffc7ee",
			// color: "#ffc7ee"
		},
   
	}
}

export default function PaymentForm() {
  const navigate = useNavigate();
  
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { closeCart, cartItems } = useShoppingCart()
    const [qrCode, setQrCode] = useState("");
    const [items, setItems] = useState([])
const fetchItems=async()=>{
  const response = db.collection('inventory');
  const data = await response.get();

  const getItems = [];
  data.docs.forEach(item => {
    // console.log(item.data());
    getItems.push(JSON.parse(JSON.stringify(item.data())))
    // console.log(JSON.stringify(item.data()))
    // setItems([...items,item.data()]);
  })
  // console.log(getItems);
  // console.log(storeItems);
  setItems(getItems);
}

useEffect(() => {
  fetchItems();
}, [])
    
    useEffect(() => {
      if (!stripe) {
        return;
      }
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );
      if (!clientSecret) {
        return;
      }
    
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
    }, [stripe]);
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);
    //   console.log(window.location.href.split("/")[0])
    //
      const { error } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: window.location.protocol + '//' + window.location.host + '/success',
        },
      }).then(function(response){
        if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
          // Handle successful payment here
          var orders = JSON.parse(localStorage.getItem("orders"));
          // console.log(orders);
          
          db.collection('orders').add({}).then(function(res){
            
            // console.log(qrCode);
            var dict = [];
            let subtotal = 0;
            let ramen = false;
            cartItems.map(cartItem => {
              // console.log("hi")
              // const res = db.collection('orders').add({
              //   name: items.find(i => i.id === cartItem.id).name, quantity: cartItem.quantity
              // });
              // db.collection('orders').doc(res).set({items: {name: items.find(i => i.id === cartItem.id).name, quantity: cartItem.quantity}});
              const item = items.find(i => i.id === cartItem.id);
              db.collection("inventory").get().then(function(res) {
                const products = res.docs.map(doc => doc.data());
                const keys = res.docs.map(doc => doc.id)
                for (let i = 0; i < products.length; i++){
                  if (products[i]['id'] === item.id){
                    db.collection('inventory').doc(keys[i]).update({amount: products[i]['amount'] - cartItem.quantity})
                  }
                }
              })

              // console.log(cartItem.quantity*item.price)
              subtotal += cartItem.quantity*item.price;
              dict.push({name: item.name, quantity: cartItem.quantity, price: item.price, id: item.id})
              if (item.name === "Ramen"){
                ramen = true;
              }
              // console.log(dict);
              // console.log('Added document with ID: ', res.id);
              // orders.push({name: items.find(i => i.id === cartItem.id).name, quantity: cartItem.quantity})
              
          })
          let orderedText = "normal";
          if (ramen){
            orderedText = "ramen";
          }

          let time = new Date().toLocaleString()
          const processingFees = subtotal*.029+.3;
          console.log(subtotal);
          const total = processingFees + subtotal;
          db.collection('orders').doc(res.id).set({items: dict, isCompleted:false, qr: "http://api.qrserver.com/v1/create-qr-code/?data=" + res.id + "&size=512x$512&bgcolor=white", total: total,
        time: time, status: orderedText, processing: processingFees});
          // db.collection('orders').doc(res.id).update({isCompleted: false});
          // db.collection('orders').doc(res.id).update({qr: "http://api.qrserver.com/v1/create-qr-code/?data=" + res.id + "&size=512x$512&bgcolor=white"});
          // db.collection('orders').doc(res.id).update({total: total});
          // db.collection('orders').doc(res.id).update({time: time});
          // db.collection('orders').doc(res.id).update({status: 'ordered'});
          // db.collection('orders').doc(res.id).update({processing: processingFees});
            if (orders === null){
              localStorage.setItem("orders", JSON.stringify([{key: res.id, time: time, total: total, processing: processingFees, qr: "http://api.qrserver.com/v1/create-qr-code/?data=" + res.id + "&size=512x$512&bgcolor=white", items: dict}]));  
            }else if (orders.length === 1){
            // console.log(dict)
            const pushItem = JSON.stringify([orders[0], {key: res.id, time: time, total: total, processing: processingFees, qr: "http://api.qrserver.com/v1/create-qr-code/?data=" + res.id + "&size=512x$512&bgcolor=white", items: dict}])
            // console.log(pushItem)
            // localStorage.setItem("orders", JSON.stringify({key: res.id, qr: "http://api.qrserver.com/v1/create-qr-code/?data=" + res.id + "!&size=512x$512&bgcolor=white", items: dict}))
            localStorage.setItem("orders", pushItem);  
          }else{
            const pushItem = JSON.stringify([...orders, {key: res.id, time: time, total: total, processing: processingFees, qr: "http://api.qrserver.com/v1/create-qr-code/?data=" + res.id + "&size=512x$512&bgcolor=white", items: dict}])
            // console.log(pushItem)
            // localStorage.setItem("orders", JSON.stringify({key: res.id, qr: "http://api.qrserver.com/v1/create-qr-code/?data=" + res.id + "!&size=512x$512&bgcolor=white", items: dict}))
            localStorage.setItem("orders", pushItem);  
          }
          })
          var delayInMilliseconds = 1000; //1 second

setTimeout(function() {
  //your code to be executed after 1 second
  window.location.href = window.location.protocol + '//' + window.location.host + '/success';
}, delayInMilliseconds);
            
        }
      });
      
     if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
      setIsLoading(false);
    };
    
    return (
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <button disabled={isLoading || !stripe || !elements} id="submit" className="stripe">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    );
  
  
  
  
  
  
  
  
  
    // const [success, setSuccess ] = useState(false)
    // const stripe = useStripe()
    // const elements = useElements()



//     const handleSubmit = async (e) => {
//         e.preventDefault()
//         const {error, paymentMethod} = await stripe.createPaymentMethod({
//             type: "card",
//             card: elements.getElement(CardElement)
//         })


//     if(!error) {
//         try {
//             const {id} = paymentMethod
//             const response = await axios.post("http://localhost:4000/payment", {
//                 amount: 1000
//             })

//             if(response.data.success) {
//                 console.log("Successful payment")
//                 setSuccess(true)
//             }

//         } catch (error) {
//             console.log("Error", error)
//         }
//     } else {
//         console.log(error.message)
//     }
// }

//     return (
//         <>
//         {!success ? 
//         <form onSubmit={handleSubmit}>
//         <fieldset className="FormGroup">
//             <div className="FormRow">
//                 <CardElement options={CARD_OPTIONS}/>
//             </div>
//         </fieldset>
//         <button class="stripe">Pay</button>
//     </form>
//         :
//        <div>
//            Success!
//        </div> 
//         }
            
//         </>
//     )
}
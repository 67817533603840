import React from "react";

import { Wrapper,Content, Navbar } from "../Account/account.style";
import HomeIcon from "@mui/icons-material/Home"
import PermIdentityIcon from "@mui/icons-material/PermIdentity"
import PaymentIcon from "@mui/icons-material/Payment"
import BoltIcon from "@mui/icons-material/Bolt"
import AllInboxIcon from "@mui/icons-material/AllInbox"
import HistoryIcon from "@mui/icons-material/History"
import LogoutIcon from "@mui/icons-material/Logout"
import SearchIcon from '@mui/icons-material/Search';

import { Link } from "react-router-dom";
import {logout} from "../firebase"
const Navigation = () => {
return (
    <Navbar>
                <div className="" style={{display:"flex", alignItems: "center", padding: "15px 0px"}}>
              
                        <div className="main" style={{fill: "black" }}>
                            <HomeIcon style={{fill: "black"}} className="logo" />
                        </div>
                 
                        <div className="text" style={{paddingLeft: "10px", alignItems:"center"}}>
                            <h2><a href="/account">Account</a></h2>
                        </div>
             
                    
                </div>
                <div className="" style={{display:"flex", alignItems: "center", padding: "15px 0px"}}>
                    <div className="main">
                        <PermIdentityIcon style={{fill: "black"}} className="logo"/>
                    </div>
                    <div className="text" style={{paddingLeft: "10px", alignItems:"center"}}>
                        <h2><a href="/">Profile Settings</a></h2>
                    </div>
                </div>
            
                <div className="" style={{display:"flex", alignItems: "center", padding: "15px 0px"}}>
                    <div className="main">
                        <BoltIcon style={{fill: "black"}} className="logo"/>
                    </div>
                    <div className="text" style={{paddingLeft: "10px", alignItems:"center"}}>
                        <h2><a href="/">Resources</a></h2>
                    </div>
                </div>
          
                <div className="" style={{display:"flex", alignItems: "center", padding: "15px 0px"}}>
                    <div className="main">
                        <HistoryIcon style={{fill: "black"}} className="logo"/>
                    </div>
                    <div className="text" style={{paddingLeft: "10px", alignItems:"center"}}>
                        <h2><a href="">Order History</a></h2>
                    </div>
                </div>
                {/* implement signout onclick right here */}

                <div className="" style={{display:"flex", alignItems: "center", padding: "15px 0px"}}>
                    <div className="main">
                        <SearchIcon style={{fill: "black"}} className="logo"/>
                    </div>
                    <div className="text" style={{paddingLeft: "10px", alignItems:"center"}}>
                        <h2><a href="/dashboard" >Tracking</a></h2>
                    </div>
                </div>

                <div className="" style={{display:"flex", alignItems: "center", padding: "15px 0px"}}>
                    <div className="main">
                        <LogoutIcon style={{fill: "black"}} className="logo"/>
                    </div>
                    <div className="text" style={{paddingLeft: "10px", alignItems:"center"}}>
                        <h2><a href="/" onClick={logout}>Sign Out</a></h2>
                    </div>
                </div>
            </Navbar>
              

)
}
export default Navigation;
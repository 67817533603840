import React from "react";
import { Card, Col, Row, Stack } from "react-bootstrap";
import Scanner from "./index";
import { formatCurrency } from "../Handler/formatCurrency.ts";
import { OrderItem } from "../Handler/OrderItem.tsx";
import { Wrapper,Content } from "./scan.style";


function round(num, places) {
    var multiplier = Math.pow(10, places);
    return Math.round(num * multiplier) / multiplier;
  }
  function clear(event){
    window.location.reload();
}
const List = (props) => {
    const items = props['items']['items']
    const isCompleted = items['isCompleted']
    return(
        <Wrapper>   
            <Content>
                
        <div style={{marginLeft: "15px", marginRight: "15px"}}>
        <h1 class="text-dark" style={{marginTop: "10px"}}>Order Details</h1>
            <div class="card">
            </div>
            </div> 
        

                <br></br>
    <Row md={2} xs={1} lg={3} className="g-3 justify-content-md-center" >
 
  <Col className='col'>
                                    
                                    <Card style={{padding:"20px"}}>
                                            {/* // <Card.Img src={items[i][1].qr} style={{ objectFit: "" }}  /> */}
                                            <center>
                                            <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                                                Order Status: {isCompleted ? <>Order has already been completed. Do not give this user any items.</> : <>Not completed. Please give the user the following items:</>}
                                                </div></center>
                                            <br></br>
                                            <Stack gap={3}>
                                            {[...Array(items['items'].length)].map((x,j) => {
                                                return(
                                                <OrderItem key={items['items'][j].id} id={items['items'][j]['id']} quantity={items['items'][j]['quantity']} />)
                                            })}
                                                <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                                                Total: {" "}{formatCurrency(round(items['total'], 2))}
                                                </div>
                                                <span className="text-muted" style={{ color: "black", fontSize: ".65rem" }}>
                                                 Date Order Placed At: {items['time']}
                                            </span>
                                            </Stack>
                                
                                        </Card>
                                        <br></br>
                                        <form onSubmit={clear}>
                                        
                                        <center> <a href="#"><button className="left" style={{width:"30%", float: "center", color: "white"}}>Scan Another</button></a></center>
</form>
                                    </Col>  
    </Row>
   </Content>
    </Wrapper>
    )
}
export default List;

import React, { useEffect, useState } from 'react';

import { Wrapper,Content } from "../Drinks/models.style";
import Carousel from 'react-bootstrap/Carousel';

import Section from "../Section";
import Interior from "../Interior_Specs";
import Specs from "../Gen_Spec";
import ShopHeader from "../ShopHeader";
import Footer from "../Footer";
import useMediaQuery from '@mui/material/useMediaQuery';
import {isMobile} from 'react-device-detect';
import { Button, Card, Col, Row, Stack } from 'react-bootstrap';
import { StoreItem } from '../Handler/StoreItem.tsx';
import storeItems from "../data/items.json";
import Context from '../Handler/Context.tsx'
import {db} from '../firebase'
// import { useLocalStorage } from '../Handler/useLocalStorage';
import { CartItem } from '../Handler/CartItem.tsx';
import { OrderItem } from '../Handler/OrderItem.tsx';
import Header from '../Header';
import { formatCurrency } from '../Handler/formatCurrency.ts';
import { valueToPercent } from '@mui/base';

// import * as ReactDOM from 'react-dom';
import ReactDOM, { render } from 'react-dom';
function clear(event){
  event.preventDefault()
  localStorage.clear()
  window.location.reload();
}


function round(num, places) {
  var multiplier = Math.pow(10, places);
  return Math.round(num * multiplier) / multiplier;
}
// localStorage.setItem("displayOrder", false)
if (localStorage.getItem("displayOrder") === null){
  localStorage.setItem("displayOrder", false)
}
// console.log(localStorage.getItem("displayOrder"))


const Orders = () =>{
  
//   const [items, setItems] = useState([])
// const fetchItems=async()=>{
//   const response = db.collection('inventory');
//   const data = await response.get();

//   const getItems = [];
//   data.docs.forEach(item => {
//     // console.log(item.data());
//     getItems.push(item.data())
//     // setItems([...items,item.data()]);
//   })
//   setItems(getItems);
// }

// useEffect(() => {
//   fetchItems();
// }, [])

// const root = ReactDOM.createRoot(document.getElementById('root'));
function round(num, places) {
    var multiplier = Math.pow(10, places);
    return Math.round(num * multiplier) / multiplier;
  }
let isEmpty = true;
let orders;
let completed = false;
// let items = [];
// let keys = [];


try{
    orders = Object.entries(JSON.parse(localStorage.getItem("orders")));
    // console.log(orders)
    isEmpty = false;
}catch (e){
    isEmpty = true;
}
console.log(isEmpty)

// const fetchItems = async(id) => {
//   const response = db.collection('orders').doc(id);
//   const data = await response.get();
//   //   console.log(data.data())
//   //   const getItems = [];
//   //   data.docs.forEach(item => {
//   //     // console.log(item.data());
//   //     console.log(item.data())
//   //     getItems.push(item.data())
//   //     // setItems([...items,item.data()]);
//   //   })
//   // setItems(data.data())
//   items = data.data();
//   // console.log(data.data());
//   //   setItems(data.data());
//   }
function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}



// useEffect(async() => {
//   const response = db.collection('orders');
//   const data = await response.get();
//   //   console.log(data.data())
//   //   const getItems = [];
//   //   data.docs.forEach(item => {
//   //     // console.log(item.data());
//   //     console.log(item.data())
//   //     getItems.push(item.data())
//   //     // setItems([...items,item.data()]);
//   //   })
//   // setItems(data.data())
//   items = data.docs.map(doc => doc.data());
//   keys = data.docs.map(doc => doc.id)

//   console.log(data.docs.map(doc => doc.data()));
//   //   setItems
  
//   })
// const [isDivVisible , setIsDivVisible ] = useState(false)
const [items, updateItems] = useState();
const [keys, updateKeys] = useState();

useEffect(() => {

  const fetchItems = async()=>{
  const response = db.collection('orders');
  const data = await response.get();
  //   console.log(data.data())
  //   const getItems = [];
  //   data.docs.forEach(item => {
  //     // console.log(item.data());
  //     console.log(item.data())
  //     getItems.push(item.data())
  //     // setItems([...items,item.data()]);
  //   })
  // setItems(data.data())
  const items = data.docs.map(doc => doc.data());
  const keys = data.docs.map(doc => doc.id)

  // console.log(data.docs.map(doc => doc.data()));
  updateItems(items)
  updateKeys(keys)
  //   setItems
  }
  fetchItems()
  }, []);
  

// const id = "";
// const items = [];
// var i = 0;
// for (const [key, value] of orders){
//     if (i === 0){
//         id = value;
//     }else{
//         items.push(value);
//     }
//     i++;
// }


    const quantity  = 0;

    document.title = "Your Orders";
    const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  
  // if(!isMobile){
    function clear(event){
        event.preventDefault()
        if (localStorage.getItem("displayOrder") === "true"){
        localStorage.setItem("displayOrder", "false")
        }else{
          localStorage.setItem("displayOrder", "true")
        }
        // localStorage.clear()
        window.location.reload();
    }

    return(
       
          <Wrapper>
            <ShopHeader />
           
<Content>
<Row md={2} xs={2} lg={2} className="g-3">
<div className='me-auto '>
<h1 class="text-dark ">Your Orders</h1>
            </div>

<div className='left'>
{localStorage.getItem("displayOrder") === "true" ? <form onSubmit={clear}>
<a href="#"><button className="" style={{width:"100%", float: "right"}}>Hide Non-Pending Orders</button></a>
        </form> : <form onSubmit={clear}>
<a href="#"><button className="" style={{width:"100%", float: "right"}}>Show Non-Pending Orders</button></a>
        </form>}

          
            </div>
</Row>
            <div class="card">
            </div>

                <br></br>
            {/* <center>
            <div class="d-flex flex-row" >
              {items && items.map(item => (
                <div class="column" key={item.id}><StoreItem {...item}></StoreItem></div>
              ))}
            </div>
            </center>  */}
            
                
           
             {isEmpty ? <> <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>You have no past orders.</div></> :  
             
             <Row md={2} xs={1} lg={3} className="g-3 justify-content-md-center">{
             [...Array(orders.length)].map((x,i) => {
                    // console.log(orders[i][1]);
                    // console.log(orders[i][1].items.length)
                    // console.log(orders)


                  //   fetchItems(orders[i][1].key).then(() => {
                  //     var delayInMilliseconds = 0; //1 second

                  //     setTimeout(function() {
                  //         // console.log(items);
                  //         try {
                  //           console.log(items['isCompleted'])
                  //         if (items['isCompleted'] === false){
                  //             completed = true;
                    
                  //             // ReactDOM.render(<><ShowOrders props={orders} i={i}></ShowOrders></>, document.getElementById('root'))
                  //             // valid = true;
                  //         }
                  //         }catch (e){
                              
                  //         }
                  //         // if (completed){
                  //         //     return <List items={items}
                  //         // }else{

                  //         // }
                  //         // console.log(completed);
                  //         // console.log(items['items'][0]['id'])
                  //         // if (!valid){
                  //         //     ReactDOM.render(<> <List items={{items}}></List></>, document.getElementById('root'))
                  //         //     db.collection('orders').doc(decoded).update({isCompleted: true})
                  //         // }else{
                  //         //     // alert("Invalid QR Code")
                  //         // }
                          
                  //         // {completed ?  <List items={items}/> : <List items={items}/>}
                  //     //    render(items);
                  //     //your code to be executed after 1 second
                  //     }, delayInMilliseconds);
                   
                  // }).then(() => {
                  //   if (!completed){
                      
                  //   //  root.render(<><ShowOrders props={orders} i={i}/> <h1>hi</h1></>)
                  //   }
                  // })
                  // {console.log("hi")}

                  try {
                    if (items[getKeyByValue(keys, orders[i][1].key)].isCompleted === false && localStorage.getItem("displayOrder") === "false"){
                    return(
                      <>
                        {/* {console.log(keys)} */}
                         <Col className='col'>
                          
                          <Card style={{padding:"20px"}}>
                                  <Card.Img src={orders[i][1].qr} style={{ objectFit: "" }}  />
                                  <br></br>
                                  <Stack gap={3}>
                                  {[...Array(orders[i][1].items.length)].map((x,j) => {
                                      return(
                                      <OrderItem key={orders[i][1].items[j].id} {...orders[i][1].items[j]} />)
                                  })}
                                  <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                                      Processing Fees:{" "}{formatCurrency(round(orders[i][1].processing, 2))}
                                      </div>
                                      <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                                      Total: {" "}{formatCurrency(round(orders[i][1].total, 2))}
                                      </div>
                                      <span className="text-muted" style={{ color: "black", fontSize: ".65rem" }}>
                                       Date Order Placed At: {orders[i][1].time}
                                  </span>
                                  </Stack>
      
                              </Card>
                          </Col> 
                  
                      {/* <h1>hi</h1>  */}
                      {/* <div id='root'> */}
                        
                      {/* <ShowOrders props={orders} id={i}/> */}
                      
                      {/* <ShowOrders props={orders} i={i}></ShowOrders> */}
                      {/* </div> */}
                 
                      
                      </>
                    )
                                }else if (localStorage.getItem("displayOrder") == "true"){
                                  return(
                                    <>
                                      {/* {console.log(keys)} */}
                                       <Col className='col'>
                                        
                                        <Card style={{padding:"20px"}}>
                                                <Card.Img src={orders[i][1].qr} style={{ objectFit: "" }}  />
                                                <br></br>
                                                <Stack gap={3}>
                                                {[...Array(orders[i][1].items.length)].map((x,j) => {
                                                    return(
                                                    <OrderItem key={orders[i][1].items[j].id} {...orders[i][1].items[j]} />)
                                                })}
                                                <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                                                    Processing Fees:{" "}{formatCurrency(round(orders[i][1].processing, 2))}
                                                    </div>
                                                    <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                                                    Total: {" "}{formatCurrency(round(orders[i][1].total, 2))}
                                                    </div>
                                                    <span className="text-muted" style={{ color: "black", fontSize: ".65rem" }}>
                                                     Date Order Placed At: {orders[i][1].time}
                                                </span>
                                                </Stack>
                    
                                            </Card>
                                        </Col> 
                                
                                    {/* <h1>hi</h1>  */}
                                    {/* <div id='root'> */}
                                      
                                    {/* <ShowOrders props={orders} id={i}/> */}
                                    
                                    {/* <ShowOrders props={orders} i={i}></ShowOrders> */}
                                    {/* </div> */}
                               
                                    
                                    </>
                                  )
                                }else if (localStorage.getItem("displayOrder") == "false"){
                                  <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>You have no pending orders. If you would like to view your completed orders please click the button.</div>
                                }
                  }catch(e){

                  }
                  // {console.log(keys)}
                  // {console.log(getKeyByValue(keys, orders[i][1].key))}
                 
                  // return(
                    // <Col className='col'>
                        
                    // <Card style={{padding:"20px"}}>
                    //         <Card.Img src={orders[i][1].qr} style={{ objectFit: "" }}  />
                    //         <br></br>
                    //         <Stack gap={3}>
                    //         {[...Array(orders[i][1].items.length)].map((x,j) => {
                    //             return(
                    //             <OrderItem key={orders[i][1].items[j].id} {...orders[i][1].items[j]} />)
                    //         })}
                    //         <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                    //             Processing Fees:{" "}{formatCurrency(round(orders[i][1].processing, 2))}
                    //             </div>
                    //             <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                    //             Total: {" "}{formatCurrency(round(orders[i][1].total, 2))}
                    //             </div>
                    //             <span className="text-muted" style={{ color: "black", fontSize: ".65rem" }}>
                    //              Date Order Placed At: {orders[i][1].time}
                    //         </span>
                    //         </Stack>

                    //     </Card>
                    // </Col>
                    // )
                  })}
                  
                  </Row>}
                  
                    
                

                

            
            
            {/* <Row md={2} xs={1} lg={3} className="g-3 justify-content-md-center">
            {items[0] && items[0].map(item => (
                <Col class="col">
                    <Card style={{border:"none"}}>
        <Card.Img src={item.qr} style={{objectFit: "cover"}} height={window.innerWidth/4} width={window.innerWidth/4}/>

        <Card.Title id="test" className="d-flex justify-content-between"><h2 id="test" style={{marginTop: "15px"}}>{item.name}</h2></Card.Title>
        <Stack gap={3}>
              <CartItem key={item.id} {...item} />
            <div className="text-muted" style={{ fontSize: "1rem", color: "black"}}>
              Processing Fees:
          
        </div>
            <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
              Total{" "}
              
            </div>
          </Stack>

        </Card>

                </Col>
              ))}
            </Row> 
     */}
            

</Content>
</Wrapper>
);
//   }

//   return(

//           <Wrapper>
//             <ShopHeader/>
           
// <Content>
// <h1 class="text-dark">Drinks</h1>
//             <div class="card">
//             </div>
//             <br></br>


//             {/* <div class="container"> */}

//             <div class="container" >
//               <div class="row">
//               <div class="col">
//               {/* <img src="/images/1.png" height={window.innerWidth/2-60} width={window.innerWidth/2-60}/>
//               <li><h2 id="test">Dr. Pepper</h2></li>
//               <p id="test">$2.00</p>
//               <div className="left">
//               <button>Buy</button></div>
//               </div> 
//               <div class="col">
//               <img src="/images/2.png" height={window.innerWidth/2-60} width={window.innerWidth/2-60}/>
//               <li><h2 id="test">Coca-Cola</h2></li>
//               <p id="test">$1.50</p>
//               <div className="left">
//               <button>Buy</button></div>
//               </div>
//               <li></li>
//               <div class="col">
//               <img src="/images/3.png" height={window.innerWidth/3} width={window.innerWidth/3}/>
//               <li><h2 id="test">Snapple</h2></li>
//               <p id="test">$1.50</p>
//               <div className="left">
//               <button>Buy</button></div> */}

//           {items.map(item => (
//                 <div class="col" key={item.id}><StoreItem {...item}><br></br></StoreItem></div>
//               ))}
//               </div>
//             </div>
//             </div>
//             {/* </div> */}
            

// </Content>
// </Wrapper>
// );



}
export default Orders;

const ShowOrders = (props) => {
  return (
    <>
        {/* <Wrapper>
            <ShopHeader />
           
<Content> */}

<Row md={2} xs={2} lg={2} className="g-3">
<div className='me-auto '>
<h1 class="text-dark ">Your Orders</h1>
            </div>

<div className='left'>
<form onSubmit={clear}>
<a href="#"><button className="" style={{width:"100%", float: "right"}}>Clear ALL Previous/Current Orders (refresh)</button></a>
        </form>
          
            </div>
</Row>
            <div class="card">
            </div>

                <br></br>
    {/* {console.log(props['props'][parseInt(props['i'])][1])} */}
    <Col className='col'>     
        <Card style={{padding:"20px"}}>
                <Card.Img src={props['props'][parseInt(props['i'])][1].qr} style={{ objectFit: "" }}  />
                <br></br>
                <Stack gap={3}>
                {[...Array(props['props'][parseInt(props['i'])][1].items.length)].map((x,j) => {
                    return(
                    <OrderItem key={props['props'][parseInt(props['i'])][1].items[j].id} {...props['props'][parseInt(props['i'])][1].items[j]} />)
                })}
                <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                    Processing Fees:{" "}{formatCurrency(round(props['props'][parseInt(props['i'])][1].processing, 2))}
                    </div>
                    <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
                    Total: {" "}{formatCurrency(round(props['props'][parseInt(props['i'])][1].total, 2))}
                    </div>
                    <span className="text-muted" style={{ color: "black", fontSize: ".65rem" }}>
                      Date Order Placed At: {props['props'][parseInt(props['i'])][1].time}
                </span>
                </Stack>

            </Card>
        </Col>
        {/* </Content></Wrapper> */}
        </>
  )
}

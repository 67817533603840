import { Offcanvas, Stack } from "react-bootstrap"
import { useShoppingCart } from "./Context.tsx"
import { CartItem } from "./CartItem.tsx"
import storeItems from "../data/items.json"
import React, { useEffect, useState } from "react"
import { formatCurrency } from "./formatCurrency.ts"
import {Elements, PaymentElement} from '@stripe/react-stripe-js';

import {LoadingCard} from "./video.style.js"
// import { ApplePay, PaymentForm } from 'react-square-web-payments-sdk';
import { CreditCard } from 'react-square-web-payments-sdk';
import StripeContainer from "../Payments/StripeContainer.tsx"
// import StripeContainer from "../Payments/StripeContainer"
import PaymentForm from "../Payments/PaymentForm"
import { loadStripe } from "@stripe/stripe-js"
import { db } from "../firebase"
import $ from "jquery"
function round(num, places) {
  var multiplier = Math.pow(10, places);
  return Math.round(num * multiplier) / multiplier;
}

function checkItems (cartItems){
  return cartItems.reduce((total, cartItem) => {
    const item = storeItems.find(i => i.id === cartItem.id)
    return cartItem.quantity
  }, 0)
}
// const app = fastify();
// async function startApp() {
//     try {
//         app.register(require('@fastify/cors'), {
//             origin: '*',
//         });
//         app.post("/pay", async (request, reply) => {
//             // our logic will go here.
//         });
//         await app.listen(PORT);
//         console.log('listening on port', PORT);
//     } catch (e) {
//         console.error(e);
//     }
// }
// startApp();
// const { fastify } = require('fastify');
// // const uuidv4 = require('uuid4');
// const { paymentsApi } = require("../../square.connect.js");
// const PORT = 3001;
// const app = fastify();

// async function server() {
//     try {
//         app.register(require('@fastify/cors'), {
//             origin: '*',
//         });
//         app.post("/pay", async (request, reply) => {
//             let body = request.body;
//             body.idempotencyKey = uuidv4();
//             let paymentResponse = paymentsApi.createPayment(body);
//             paymentResponse.then((response) => {
//                 console.log(response)
//                 reply.send(response)
//             })
//         });
//         await app.listen(PORT);
//         console.log('listening on port', PORT);
//     } catch (e) {
//         console.error(e);
//     }
// }
// server();


// function calculateProcessing(cartItems, items){
//   return checkItems(cartItems) === 0 ? 0 : round(.029*
//     cartItems.reduce((total, cartItem) => {
//       const item = items.find(i => i.id === cartItem.id)
//       return total + (item?.price || 0) * cartItem.quantity
//     }, 0)
//   +.3,2)
// }

// function calculateTotal(cartItems, items){
//   console.log("total")
//   return (cartItems.reduce((total, cartItem) => {
//       const item = items.find(i => i.id === cartItem.id)
//       return total + (item?.price || 0) * cartItem.quantity
//     }, 0) + calculateProcessing(cartItems, items))
// }

type ShoppingCartProps = {
    isOpen: boolean
  }
  
  const PUBLIC_KEY = "pk_test_51LNpQ7BQtIlobxpX3EqvGmAtjxTN1u8h3qgzwLhZscWI3hbMENDp9dtBPhItcpyCRnL3NgpdS8HvKQ9DQfLg1rC900EneFfA4T"

  const stripeTestPromise = loadStripe(PUBLIC_KEY)

  export function Cart({ isOpen }: ShoppingCartProps) {
    const { closeCart, cartItems } = useShoppingCart()
    const [items, setItems] = useState([])


    const fetchItems=async()=>{
      const response = db.collection('inventory');
      const data = await response.get();
    
      const getItems = [];
      data.docs.forEach(item => {
        // console.log(item.data());
        getItems.push(JSON.parse(JSON.stringify(item.data())))
        // console.log(JSON.stringify(item.data()))
        // setItems([...items,item.data()]);
      })
      // console.log(getItems);
      // console.log(storeItems);
      setItems(getItems);
    }
    
    useEffect(() => {
      console.log("hi")
      fetchItems();
      
    }, [])

    $('a').click( function() {
      // $('.off-canvas').foundation('close');
      closeCart()
      // alert("hi")
  });
//3.78

    return (
      <>
      <Offcanvas autoFocus={true} collapseOnSelect show={isOpen} onHide={closeCart} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><div style={{color: "black",}}>Cart</div></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Stack gap={3}>
            {cartItems.map(item => (
              <CartItem key={item.id} {...item} />
            ))}
            <div className="text-muted" style={{ fontSize: "1rem", color: "black"}}>
              Processing Fees:
          {/* {formatCurrency(calculateProcessing(cartItems, items))} */}
          
          {formatCurrency(checkItems(cartItems) === 0 ? 0 : round(.029*
    cartItems.reduce((total, cartItem) => {
      const item = items.find(i => i.id === cartItem.id)
      return total + (item?.price || 0) * cartItem.quantity
    }, 0)
  +.3,2))}
        </div>
            <div className="ms-auto fw-bold fs-5" style={{color: "black",}}>
              Total{" "}
              {/* {formatCurrency(calculateTotal(cartItems, items))} */}
              {formatCurrency(cartItems.reduce((total, cartItem) => {
      const item = items.find(i => i.id === cartItem.id)
      return total + (item?.price || 0) * cartItem.quantity
    }, 0) + (checkItems(cartItems) === 0 ? 0 : round(.029*
      cartItems.reduce((total, cartItem) => {
        const item = items.find(i => i.id === cartItem.id)
        return total + (item?.price || 0) * cartItem.quantity
      }, 0)
    +.3,2)))}
            </div>
          </Stack>
          <br></br>
           {/* <PaymentForm applicationId={"sandbox-sq0idb-UcpwGjPDW3i1NlED_m105w"} cardTokenizeResponseReceived={async(token, buyer) => {
            let body = JSON.stringify({sourceId: token.token, amountMoney: {amount:calculateTotal(cartItems)*100, currency: 'USD',}})
            const paymentResponse = await fetch('http://localhost:3001/pay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      });
      if (paymentResponse.ok) {
        return paymentResponse.json();
      }

    }} locationId={"L6QVY1GE1Z1SQ"} >

      <CreditCard />
    
    </PaymentForm>  */}
    	{/* {clientSecret && (
				<Elements options={options} stripe={stripeTestPromise}>
				  <PaymentForm />
				</Elements>
			  )} */}
        <StripeContainer amount={cartItems.reduce((total, cartItem) => {
      const item = items.find(i => i.id === cartItem.id)
      return total + (item?.price || 0) * cartItem.quantity
    }, 0) + (checkItems(cartItems) === 0 ? 0 : round(.029*
      cartItems.reduce((total, cartItem) => {
        const item = items.find(i => i.id === cartItem.id)
        return total + (item?.price || 0) * cartItem.quantity
      }, 0)
    +.3,2))}/>
        {/* <form>
      <PaymentElement />
      <button>Submit</button>
    </form> */}
        </Offcanvas.Body>
      </Offcanvas>
      </>
    )
  }
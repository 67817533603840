import styled from 'styled-components';

export const Wrapper = styled.div`
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;

    .carousel{
        // width: 100vw;
        // height: 100vh;
    }
    .carousel-control-next, .carousel-control-prev {
        top: 20%;
        bottom: 20%;
      }
    //   background: #EBF0F5;
    .wrapper {
        text-align: center;
        padding: 40px 0;
        background: #EBF0F5;
      }
`;

export const Content = styled.div`
    
@media screen and (min-width: 1000px){
    .particular{
    display:none;
    // background-image: url("/images/bg-deca.png") no-repeat;
    background-repeat: no-repeat;
    }
}

    @media screen and (max-width: 500px){
        .hidden{
            display:none;
            // background-image: url('/images/verticalbg.png') no-repeat;
            background-repeat: no-repeat;
        }
    }

    .bg{
       height:100vh;
    }

    a{
        color: white;
        text-decoration: none;
    }
    
        h1 {
          color: #88B04B;
          font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
          font-weight: 900;
          font-size: 40px;
          margin-bottom: 10px;
        }
        p {
          color: #404F5E;
          font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
          font-size:20px;
          margin: 0;
        }
      i {
        color: #9ABC66;
        font-size: 100px;
        line-height: 200px;
        margin-left:-15px;
      }
      .card {
        background: white;
        padding: 60px;
        border-radius: 4px;
        box-shadow: 0 2px 3px #C8D0D8;
        display: inline-block;
        margin: 0 auto;
      }

    button{
    


        padding: 12px 20px;
        width: 260px;
        border-radius: 20px;
        outline: none;
        border: none;
        font-size: 0.8rem;
        font-weight: 400;
        letter-spacing: 0.6px;
        opacity: 0.9;
        :hover{
            cursor: pointer;
        }
    }

    .left button{
        background-color: rgba(23,26,32,1);
        // margin-right: 25px;
        color: white;
    }
`;
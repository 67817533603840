import styled from 'styled-components';

export const Wrapper = styled.div`
    overflow-x: hidden;
    // width: 100vw;
    // height: 100vh;
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;

    .carousel{
        // width: 100vw;
        // height: 100vh;
    }
    .carousel-control-next, .carousel-control-prev {
        top: 20%;
        bottom: 20%;
      }

`;

export const Content = styled.div`
    
@media screen and (min-width: 1000px){
    .particular{
    display:none;
    // background-image: url("/images/bg-deca.png") no-repeat;
    background-repeat: no-repeat;
    }
}

    @media screen and (max-width: 500px){
        .hidden{
            display:none;
            // background-image: url('/images/verticalbg.png') no-repeat;
            background-repeat: no-repeat;
        }
    }

    .bg{
       height:100vh;
    }

`;